@import '../../styles/customMediaQueries.css';

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}
.arabicText{
  direction: rtl;
  & .navigation{
    padding: 0 !important;
  }
  & .mainColumnInboxPage{
    padding: 0 !important;
  }
}
.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}
.inboxPageSideNav {
  max-width: 1280px;
  margin: 0 auto;
  padding: 48px 30px 161px;
  width: 100%;
  min-height: 80vh;
  @media (max-width: 767px) {
    padding: 36px 16px 120px;
  }
}
.mainColumnInboxPage {
  padding: 0px;
}
.heading {
  position: relative;
}
.bookingTable {
  & table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    text-align: left;

    & thead {
      border-radius: 8px 8px 0 0;
      background-color: #f9f9f9;
      height: 102px;
      & th {
        padding: 20px;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;

        color: #8e8e8e;
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 25%;
          text-align: center;
        }
        &:nth-child(5) {
          width: 15%;
          text-align: right;
        }
      }
    }

    & tbody {
      height: 108px;
      & td {
        padding: 11px 20px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        color: #000;
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          position: relative;
          width: 20%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 25%;
          text-align: center;
        }
        &:nth-child(5) {
          width: 15%;
          text-align: right;
        }
      }
    }
  }
}
.navigation {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;

  border-top-width: 1px;
  border-top-color: transparent;
  border-top-style: solid;
  background-color: unset;
  box-shadow: unset;
  width: 100%;
  @media (max-width: 767px) {
    width: calc(100% - 32px);
  }
  @media (--viewportLarge) {
    flex-wrap: unset;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
}
.rootSideNav {
  background-color: #fff;
}
.title {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 22px;
  color: #000;
  padding: 0;
  margin: 12px 0 50px;
}

.tabs {
  padding-top: 2px;
  margin-bottom: 36px;
  display: flex;
  gap: 24px;
  flex-direction: row;
  overflow-x: auto;
  @media (max-width: 767px) {
    gap: 12px;
  }
  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 24px;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }

  & :global(.NamedLink_active) {
    border-bottom: 4px solid #ecbc30;
  }
  & > a {
    background-image: unset !important;
     font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    padding-bottom: 16px;
    width: fit-content;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.error {
  color: var(--colorFail);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.tableWrapper {
  @media (max-width: 767px) {
    width: calc(100vw - 32px);
    overflow-x: auto;
  }
}
.orderTableWrapper {
  & .listItem {
    &:nth-child(odd) {
      background-color: #fff !important;
    }
  }
}
.itemList {
  margin: 0;
  padding: 0;
  min-width: 700px;
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  border: none;
  margin: 0;
  padding: 0;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #efeff4;
  &:last-child {
    border-bottom: none;
  }
  &:nth-child(odd) {
    background-color: #fff2f2;
  }
  &:last-child {
    border-bottom: 1px solid #efeff4;
  }
}

.item {
}

.itemAvatar {
  display: flex;
  align-items: center;
  margin-right: 8px;
  & > a, & img{
    border-radius: 14px;
    width: 80px;
    height: 80px;
    @media (max-width: 767px) {
      width: 70px;
    height: 70px;
    }
    & svg{
      width: 70%;
      height: 70%;
      fill: transparent;
      & path{
        stroke: #fff;
      }
    }
  }

  @media (--viewportMedium) {
    align-items: unset;
  }

  @media (--viewportLarge) {
    margin-top: 3px;
    margin-right: 7px;
  }
}

.itemLink {
  flex: 1;

  /* Layout */

  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.rowNotificationDot {
  grid-area: dot;
  justify-self: center;
  align-self: flex-start;
  margin-top: 8px;

  width: 6px;
  height: 6px;
  position: absolute;
  left: -23px;
  top: 33px;
  @media (max-width: 1023px) {
    left: -16px;
  }
  @media (--viewportLarge) {
    margin-top: 5px;
    margin-left: 5px;
  }

  @media (--viewportLarge) {
    justify-self: flex-start;
    width: 10px;
    height: 10px;
  }
}

.notificationDot {
  color: var(--colorWhite);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ecbc30;
}

.itemUsername {
  grid-area: name;

  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.itemTitle {
  composes: marketplaceTinyFontStyles from global;
  grid-area: item;
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 0;
}

.itemDetails {
  composes: marketplaceTinyFontStyles from global;
  grid-area: details;
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 0;
}

.itemState {
  grid-area: state;

  /* Font */
  composes: marketplaceTinyFontStyles from global;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: normal;

  justify-self: flex-end;
  text-align: end;

  margin-top: 6px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  font-weight: 700;
  color: #ecbc30;
}

.stateConcluded {
  color: var(--colorGrey300);
}
.stateNoActionNeeded {
  color: #b52b00;
  font-weight: 700;
}
.bookingActionNeeded {
  color: var(--colorGrey700);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--colorGrey300);
}

.lastTransitionedAtEmphasized {
  color: var(--colorGrey700);
  font-weight: var(--fontWeightMedium);
}

.lastTransitionedAtNotEmphasized {
  color: var(--colorGrey300);
}

.bannedUserLink {
  & .itemUsername,
  &:hover .itemUsername,
  & .stateName {
    color: var(--colorGrey300);
  }
}
