.mainLayout {
  background-color: #fff;
}
.arabicText{
  direction: rtl;
  &>svg{
    transform: rotate(180deg);
  }
  & table{
    & th, & td{
      text-align: right !important;
    }
  }
}
.arabicRowReverse{
  &>svg{
    transform: rotate(180deg);
    margin-left: 0;
    margin-right: 12px !important;
  }
}
.completeOnboardingModal {
  & .topbarLink {
    & button {
      border-radius: 100px;
      width: 90%;
      margin: 0 auto;
    }
    &:focus,
    &:hover {
      text-decoration: unset;
    }
  }

  & .completeOnboardingText {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 40px 0 60px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
}
.tableWrapper{
  @media (max-width: 767px) {
    width: calc(100vw - 48px);
    overflow-x: auto;
  }
  ::-webkit-scrollbar {
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  & .itemList{
    min-width: 700px;
    & li{
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: #efeff4;
    }
  }
}
.dashboardWrapper {
  margin: 70px auto;
  max-width: 1280px;
  padding: 0 24px;
  width: 100%;
  & .dashboardHeader {
    & .dashboardHeading {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
    }

    & .dashboardSubHeading {
      font-size: 12px;
      color: #6c7275;
      font-weight: 400;
      line-height: 28px;
    }
  }

  & .dashboardBody {
    margin-top: 13px;

    & .dealsCardsWrapper {
      gap: 15px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @media (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
      }
      & .dealCard {
        padding: 30px;
        display: flex;
        height: 155px;
        border-radius: 12px;
        justify-content: space-between;
        @media (max-width: 767px) {
          padding: 16px;
          
        }
        &:nth-child(1) {
          background: #007bff1a;
        }

        &:nth-child(2) {
          background: #10b9811a;
        }

        &:nth-child(3) {
          background: #ffd0461a;
        }

        &:nth-child(4) {
          background: #7b61ff12;
        }

        & .cardLeft {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          & .number {
            font-size: 24px;
            font-weight: 800;
            color: #202020;
            margin-bottom: 8px;
            line-height: 28.64px;
          }

          & .heading {
            font-size: 14px;
            color: #8a8a8f;
            font-weight: 400;
            line-height: 16.71px;
            & a {
              text-decoration: none;
            }
          }
        }
      }
    }

    & .badgeCardBox {
      gap: 20px;
      display: grid;
      margin-top: 28px;
      grid-template-columns: repeat(4, 1fr);
      cursor: pointer;
      @media (max-width: 767px) {
        grid-template-columns: repeat(3, 1fr);
        
      }
      @media (max-width: 580px) {
        grid-template-columns: repeat(2, 1fr);
        
      }
      & > a {
        &:hover,
        &:focus {
          text-decoration: unset;
        }
      }

      & .badgeCard {
        gap: 10px;
        display: flex;
        padding: 16px 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        background: #fff6da66;

        & a {
          text-decoration: none;
        }

        & .heading {
          font-size: 14px;
          color: #273d52;
          font-weight: 600;
          line-height: 15px;
        }
      }
    }

    & .cartList {
      gap: 18px;
      display: grid;
      margin-top: 32px;
      grid-template-columns: repeat(3, 1fr);

      & .ratioCard {
        padding: 32px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0px 1px 3px 0px #00000014;

        & .heading {
          display: flex;
          font-size: 24px;
          font-weight: 600;
          color: #202020;
          margin-bottom: 8px;
          border-radius: 12px;
          line-height: 28.64px;
          justify-content: space-between;

          & .threeDot {
            cursor: pointer;

            &:hover {
              & svg {
                & path {
                  fill: #333;
                }
              }
            }
          }
        }

        & .subHeading {
          font-size: 14px;
          font-weight: 400;
          color: #898989;
          line-height: 16.94px;
        }

        & .chartBox {
          margin-top: 25px;
        }
      }
    }

    & .bookingOrder {
      margin-top: 28px;

      & .bookingHeader {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        justify-content: space-between;

        & .bookingHeading {
          font-size: 20px;
          font-weight: 500;
          color: #1c2a53;
          line-height: 30px;
        }

        & .moreLink {
          & a {
            display: flex;
            font-size: 14px;
            font-weight: 600;
            color: #555f7e;
            line-height: 22px;
            align-items: center;

            & svg {
              margin-left: 12px;
            }
          }
        }
      }

      & .bookingTable {
        & table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 1rem;
          background-color: transparent;

          & thead {
            border-radius: 8px 8px 0 0;
            background-color: #f8f8f8;

            & th {
              padding: 20px;
              font-size: 14px;
              font-weight: 600;
              color: #8e95a9;
              line-height: 22px;
              text-align: left;
              &:nth-child(1) {
                width: 15%;
              }
              &:nth-child(2) {
                width: 20%;
              }
              &:nth-child(3) {
                width: 15%;
              }
              &:nth-child(4) {
                width: 25%;
                text-align: center;
              }
              &:nth-child(5) {
                width: 15%;
                text-align: right;
              }
            }
          }

          & tbody {
            & td {
              padding: 20px;
              text-align: left;

              & .heading {
                font-size: 14px;
                font-weight: 600;
                color: #555f7e;
                line-height: 22px;
              }

              & .mainHeading {
                font-size: 14px;
                font-weight: 400;
                color: #555f7e;
                line-height: 22px;
              }

              & .amountDiscountBadge {
                padding: 2px;
                height: 18px;
                color: #fff;
                display: flex;
                font-size: 10px;
                font-weight: 400;
                line-height: 20px;
                border-radius: 40px;
                align-items: center;
                justify-content: center;
                background-color: #bf1b82;
              }

              & .bookedText {
                font-size: 14px;
                font-weight: 400;
                color: #ffa000;
                line-height: 22px;
              }

              & .actionButton {
                gap: 16px;
                display: flex;

                & .actionIcon {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
.importantUpdateModal{
  &>div{
    &>div{
      border-radius: 16px !important;
      flex-basis: 1025px !important;
      padding-top: 80px !important;
      padding-bottom: 80px !important;
      &>button{
        & svg{
          border: 2px solid #000000;
    border-radius: 100px;
    padding: 3px;
        }
      }
    }
  }
}
.container {
  text-align: center;
  .icon {
    font-size: 50px;
    color: #f5c000;
  }

  .title {
    font-family: Actor;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 1px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;

    padding: 0;
    margin: -16px 0 30px;
  }

  .description {
    font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 26px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000;

    margin: 0 auto 30px;
    padding: 0;
    max-width: 675px;
  }

  .requirements {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .requirements li {
    margin: 5px 0;
    text-align: center;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FF9500;

    padding: 0;
    margin: 0 0 4px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }

  .addButton {
    background-color: #FFD046;
    color: #111111;
    border: none;
    padding: 10px 26px;
    border-radius: 40px;
    /* font-family: SF Pro Text; */
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
  }

  .addButton:hover {
    background-color: #e0a800;
  }

  .updateLater {
    background: none;
    color: #000000;
    border: none;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
    cursor: pointer;
    text-decoration: unset;
  }

  .updateLater:hover {
    color: #000;
    text-decoration: underline;
  }
}





