.mainLayout {
  background-color: #fff;
}

.arabicText {
  direction: rtl;

  & .moreLink {
    & svg {
      transform: rotate(180deg);
    }
  }
}

.arabicRowReverse {
  flex-direction: row-reverse;
}

.completeOnboardingModal {
  & .topbarLink {
    & button {
      border-radius: 100px;
      width: 90%;
      margin: 0 auto;
    }

    &:focus,
    &:hover {
      text-decoration: unset;
    }
  }

  & .completeOnboardingText {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 40px 0 60px;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
}

.dashboardWrapper {
  width: 100%;
  margin: 70px auto;
  max-width: 1228px;

  & .dashboardHeader {
    & .dashboardHeading {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
    }

    & .dashboardSubHeading {
      font-size: 12px;
      color: #6c7275;
      font-weight: 400;
      line-height: 28px;
    }
  }

  & .dashboardBody {
    margin-top: 13px;

    & .dealsCardsWrapper {
      gap: 15px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
      }

      & .dealCard {
        padding: 30px;
        display: flex;
        height: 155px;
        border-radius: 12px;
        justify-content: space-between;

        @media (max-width: 767px) {
          padding: 16px;
        }

        &:nth-child(1) {
          background: #007bff1a;
        }

        &:nth-child(2) {
          background: #10b9811a;
        }

        &:nth-child(3) {
          background: #ffd0461a;
        }

        &:nth-child(4) {
          background: #7b61ff12;
        }

        & .cardLeft {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          & .number {
            font-size: 24px;
            font-weight: 800;
            color: #202020;
            margin-bottom: 8px;
            line-height: 28.64px;
          }

          & .heading {
            font-size: 14px;
            color: #8a8a8f;
            font-weight: 400;
            line-height: 16.71px;

            @media (max-width: 400px) {
              font-size: 13px;

            }

            & a {
              text-decoration: none;
            }
          }
        }
      }
    }

    & .badgeCardBox {
      gap: 20px;
      display: grid;
      margin-top: 28px;
      grid-template-columns: repeat(4, 1fr);
      cursor: pointer;

      &>a {

        &:hover,
        &:focus {
          text-decoration: unset;
        }
      }

      & .badgeCard {
        gap: 10px;
        display: flex;
        padding: 16px 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        background: #fff6da66;

        & a {
          text-decoration: none;
        }

        & .heading {
          font-size: 14px;
          color: #273d52;
          font-weight: 600;
          line-height: 15px;
        }
      }
    }

    & .cartList {
      gap: 18px;
      display: grid;
      margin-top: 32px;
      grid-template-columns: repeat(3, 1fr);

      & .ratioCard {
        padding: 32px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0px 1px 3px 0px #00000014;

        & .heading {
          display: flex;
          font-size: 24px;
          font-weight: 600;
          color: #202020;
          margin-bottom: 8px;
          border-radius: 12px;
          line-height: 28.64px;
          justify-content: space-between;

          & .threeDot {
            cursor: pointer;

            &:hover {
              & svg {
                & path {
                  fill: #333;
                }
              }
            }
          }
        }

        & .subHeading {
          font-size: 14px;
          font-weight: 400;
          color: #898989;
          line-height: 16.94px;
        }

        & .chartBox {
          margin-top: 25px;
          position: relative;

          .service {
            position: absolute;
            z-index: 10;
            display: flex;
            align-items: start;
            gap: 5px;

            & .title {
              display: block;
              font-weight: 500;
            }

            & .revenue {
              display: block;
              font-weight: 500;
            }

            & .dot {
              width: 12px;
              height: 12px;
              border-radius: 50%;
            }
          }


          & .service1 {
            top: 0;
            left: 0;

            & .dot {
              background-color: #FFC416;
            }
          }

          & .service2 {
            top: 0;
            right: 0;

            & .dot {
              background-color: #70B6C1;
            }
          }

          & .service3 {
            bottom: 0;
            left: 0;

            & .dot {
              background-color: #FFB1B7;
            }
          }

          & .service4 {
            bottom: 0;
            right: 0;

            & .dot {
              background-color: #BCD18A;
            }
          }

          & .totalRevenue {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 13px;
            flex-direction: column;
            z-index: 10;

            & .totalAmount {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
    }

    & .bookingOrder {
      margin-top: 28px;

      & .bookingHeader {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        justify-content: space-between;

        & .bookingHeading {
          font-size: 20px;
          font-weight: 500;
          color: #1c2a53;
          line-height: 30px;
        }

        & .moreLink {
          & a {
            display: flex;
            font-size: 14px;
            font-weight: 600;
            color: #555f7e;
            line-height: 22px;
            align-items: center;

            & svg {
              margin-left: 12px;
            }
          }
        }
      }

      & .bookingTable {
        & table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 1rem;
          background-color: transparent;

          & thead {
            border-radius: 8px 8px 0 0;
            background-color: #f8f8f8;

            & th {
              padding: 20px;
              font-size: 14px;
              font-weight: 600;
              color: #8e95a9;
              line-height: 22px;
              text-align: left;

              &:nth-child(1) {
                width: 15%;
              }

              &:nth-child(2) {
                width: 20%;
              }

              &:nth-child(3) {
                width: 15%;
              }

              &:nth-child(4) {
                width: 25%;
                text-align: center;
              }

              &:nth-child(5) {
                width: 15%;
                text-align: right;
              }
            }
          }

          & tbody {
            & td {
              padding: 20px;
              text-align: left;

              & .heading {
                font-size: 14px;
                font-weight: 600;
                color: #555f7e;
                line-height: 22px;
              }

              & .mainHeading {
                font-size: 14px;
                font-weight: 400;
                color: #555f7e;
                line-height: 22px;
              }

              & .amountDiscountBadge {
                padding: 2px;
                height: 18px;
                color: #fff;
                display: flex;
                font-size: 10px;
                font-weight: 400;
                line-height: 20px;
                border-radius: 40px;
                align-items: center;
                justify-content: center;
                background-color: #bf1b82;
              }

              & .bookedText {
                font-size: 14px;
                font-weight: 400;
                color: #ffa000;
                line-height: 22px;
              }

              & .actionButton {
                gap: 16px;
                display: flex;

                & .actionIcon {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.flyerBanner{
  width: 100%;
  padding: 25px;
  background-color: #F4B31B;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 30px;
}